
/* *{
    overflow: hidden;
} */

.main{
    width: 100%;
    height: 100vh;
    background: url('../../images/start_to_play_background.png') no-repeat center center fixed;
    background-size: cover;
    /*height: 120vh;*/
    position: relative;
    overflow: hidden;
}



.main-up{
    margin-top: 5%;
    display: block;
    position: absolute;
    top: 0;
    width: 100%;

}

.main-up img{
    max-width: 100%;
    display: block;
    margin: 0 auto;
}

.main-btn{
    display: flex;
    height: 100vh;
    align-items: flex-end;
}

.main-btn button{
    font-size: 45px;
    display: block;
    background: url("../../images/start_btn.png");
    width: 388px;
    height: 119px;
    text-align: center;
    margin: 0 auto 135px auto;
    transition: all 1s;
    text-decoration: none;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    border: 0;
}

.main-btn a:hover{
    background: linear-gradient(180deg, #30f747 0%, #1ab369 100%);

}

@media screen and (max-width: 1699px){
    .main-btn button {
        font-size: 35px;
        display: block;
        background: url('../../images/start_btn.png');
        width: 300px;
        height: 90px;
        text-align: center;
        margin: 0 auto 135px auto;
        transition: all 1s;
        text-decoration: none;
        color: #fff;
        font-weight: bold;
        cursor: pointer;
    }
}

@media screen and (max-width: 768px) {
    .navbar-left ul, .navbar-right{
        display: none;
    }

}



