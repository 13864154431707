h4{
    margin: 0;
}

.main-workplace.inventory{
    justify-content: center;

}

.inventory .main-main{
    margin-right: 0;
}


.inventory .container{
    height: 63%;
    padding: 126px 120px;
}


/* ITEMS */

.inventory-item{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 234px;
    height: 429px;
    background-color: rgba(0, 0, 0, 0.103);
    border-radius: 10px;
    text-align: center;
}

.inventory-item__title{
    padding: 22px 0;
    color: #fff;
}

.inventory-item__title h4{
    font-size: 24px;
}

.inventory-item__img{
    width: 70%;
    height: 40%;
    margin: 20px auto 0 auto;
}

.inventory-item__img img{
    width: 100%;
    height: 100%;
}
.inventory-item__info{
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inventory-item__info img{
    width: 32px;
    margin-right: 15px;
}

.inventory-item__info span{
    font-weight: bold;
    font-size: 18px;
    color: #fff;
}



.inventory-item__btn{
    margin-top: 20px;
    margin-bottom: 26px;
}

.inventory-item__btn button{
    padding: 10px 50px;
    background-color: #0000006e;
    background-image: url("../../images/green_btn.png");
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-weight: bold;
    font-size: 22px;
    cursor: pointer;
}

/*-------*/

@media screen and (max-width: 1380px){
    .inventory-item{
        width: 170px;
        height: 290px;
    }
}