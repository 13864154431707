.workplace{
    /*background-color: rgb(0, 0, 0);*/
    /*background-image: url('../../images/ingame_background.png');*/
    /*background-repeat: no-repeat;*/
    /*background-size: cover;*/
    /*position: fixed;*/
    /*width: 100%;*/
    /*height: 100vh;*/
    /*overflow: auto;*/
}

::-webkit-scrollbar {
    width: 10px;
}
::-webkit-scrollbar-thumb {
    background: #7c4e94;
    border-radius: 200px;
}
::-webkit-scrollbar-track {
    border-radius: 10px;
}

.main-workplace{
    position: relative;
    display: flex;
    margin: 0 auto;
    height: 676px;
    justify-content: center;
}

.main-workplace-sidebar{
    position: absolute;
    left: 0;
    width: 245px;
    height: 676px;
    background-image: url('../../images/workplaces_bg.png');
    background-repeat: no-repeat;
    border-radius: 10px;
}

.workplace .main-main{
    background: #725c5385;
    width: 1209px;
    background: url('../../images/main_window.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border-radius: 10px;
    position: relative;
}

.main-main .main-title{
    background-image: url('../../images/main_window_title_bg.png');
    background-position: center top;
    background-repeat: no-repeat;
    position: absolute;
    top: -3%;
    left: 0;
    width: 100%;
    height: 15%;
    text-align: center;
}

.main-main .main-title h2{
    display: inline-block;
    font-size: 28px;
    font-weight: bold;
    padding: 18px;
    color: #fff;
    text-shadow: 0 0 7px #FF9600CC;
    margin: 0;
}



.main-main .close{
    text-align: right;
}

.main-main-wrapper{
    padding: 62px 120px 53px 120px;
}

.empty{
    text-align: center;
    padding: 215px 120px;
}

.main-workplace-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

}

.workplace-header-right{
    display: flex;
    align-items: center;
}

.workplace-header-right p{
    display: flex;
    align-items: center;
}

.main-workplace-header .time{
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    color: #000000;
    padding: 0;
    margin: 0;
}

.main-workplace-header .timer{
    color: #ffffff;
    font-size: 20px;
    text-align: center;
}

.main-workplace-header .timer p {
    margin: 0;
}

.start-work_btn{
    background-color: transparent;
    background-image: url("../../images/green_btn.png");
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    margin-left: 24px;
    text-align: center;
    padding: 9px 28px;
    color: #ffffff;
    font-size: 22px;
    border: 0;
}

.start-work_btn.disabled{
    background-image: url("../../images/unactive_green_btn.png");
    cursor: default;
}


.main-workplace-header button{
    color: #fff;
    font-weight: 700;
    cursor: pointer;
}


.main-workplace-header span{
    margin-left: 15px;
    font-weight: bold;
}

.main-workplace-header img{
    width: 28px;
    height: 24px;
    margin-left: 10px;
}

.main-main-list{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    overflow-y: auto;
    height: 495px;
    /*padding-right: 10px;*/
}

.no-tools{
    height: 46.1vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: white;
}

/* ITEMS */

.workplaces-item{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 234px;
    height: 482px;
    background-color: rgba(0, 0, 0, 0.103);
    border-radius: 10px;
    margin-bottom: 10px;
}

.workplaces-img{
    margin: 0 auto;
    width: 80%;
    position: relative;
}

.available-img{
    margin-top: 31px;
}

.workplaces-img_name{
    position: absolute;
    bottom: 20px;
    left: 35px;
    margin: 0;
    padding: 0;
    color: #fff;
    font-size: 18px;
}


.unequip-img{
    margin-top: 140px;
}

.locked-img{
    margin-top: 145px;
}

.workplaces-img img{
    width: 100%;
    height: 100%;
}

.produces{
    text-align: center;
    font-size: 18px;
}

.produces p{
    padding: 0;
    margin: 0;
    color: #1A1A1A;
    font-weight: bold;
}


.equip .workplaces-img{
    width: 92px;
    height: 92px;
}


.lock .workplaces-img{
    width: 58px;
    height: 82px;
}

/*-----*/
/*.main-main-contant{*/
/*    overflow-x: scroll;*/
/*}*/

.main-main-contant .main-main-info{
    font-size: 18px;
}

.main-main-info p span {
    font-weight: bold;
}




.main-main-contant .main-main-info img{
    width: 28px;
    height: 24px;
    margin-right: 10px;
}

.btn-equip, .btn-unequip, .btn-lock{
    font-weight: bold;
    margin-bottom: 20px;
}

.btn-unequip button{
    border: 0;
    font-size: 22px;
    display: block;
    margin: 0 auto;
    color: #fff;
    text-decoration: none;
    background-color: transparent;
    background-image: url("../../images/red_btn.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 11px 50px;
    cursor: pointer;
}

.btn-equip button{
    border: 0;
    font-size: 22px;
    display: block;
    margin: 0 auto;
    color: #fff;
    text-decoration: none;
    background-color: #0000006e;
    background-image: url("../../images/green_btn.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 11px 50px;
    cursor: pointer;
}

.btn-equip.unequip button{
    display: block;
    margin: 0 auto;
    color: #fff;
    text-decoration: none;
    background: #ff0000;
    padding: 10px 40px;
    cursor: pointer;
}

.btn-lock button{
    border: 0;
    font-size: 22px;
    display: block;
    margin: 0 auto;
    color: #fff;
    text-decoration: none;
    background-color: #0000006e;
    background-image: url("../../images/dark_btn.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 11px 50px;
    cursor: pointer;

}



.main-workplace-sidebar::-webkit-scrollbar {
    width: 10px;
}
::-webkit-scrollbar-thumb {
    background: #7c4e94;
    border-radius: 200px;
}
::-webkit-scrollbar-track {
    border-radius: 10px;
}

.main-workplace-sidebar__wrapper{
    height: 95%;
}

.main-workplace-sidebar__list{
    width: 85%;
    overflow-y: auto;
    height: 100%;
    margin: 10px auto;
    text-align: center;
}



.no-workplaces{
    margin: 0;
    font-size: 22px;
    color: #fff;
    text-align: center;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-weight: 600;
}

.main-workplace-sidebar__item{
    width: 158px;
    height: 216px;
    padding: 13px 9px;
    /* background: #6b4c3e8a; */
    border-radius: 8px;
    cursor: pointer;
}

.main-workplace-sidebar__item img{
    width: 100%;
    height: 100%;
}

.main-workplace-sidebar__item.wp-active{
    background-image: url("../../images/select_workplace.png");
    background-size: contain;
    background-repeat: no-repeat;
}

.main-workplace-sidebar__item:hover{
    background-image: url("../../images/select_workplace.png");
    background-size: contain;
    background-repeat: no-repeat;
}

.main-workplace-sidebar__item .sidebar__item__container{
    cursor: pointer;
}
.main-workplace-sidebar__item .sidebar__item__container img{
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1815px) {

    .main-workplace{
        height: 630px;
    }

    .workplace .main-main{
        width: 1075px;
        height: 630px;
    }

    .workplaces-item{
        width: 200px;
        height: 450px;
    }

    .main-workplace-sidebar{
        width: 220px;
        height: 630px;
    }

    .main-main-wrapper{
        padding: 62px 120px 7px 120px;
    }

    .main-workplace-sidebar__item{
        width: 145px;
        height: 200px;
    }

    .nav-list li{
        width: 167px;
    }

    .start-work_btn{
        width: 120px;
        height: 35px;
    }

    .start-work_btn {
        padding: 5px 15px;
        font-size: 18px;
    }

    .btn-equip button{
        font-size: 18px;
    }

    .btn-unequip button{
        font-size: 18px;
    }

    .btn-lock button{
        font-size: 18px;
    }

    .produces{
        font-size: 16px;
    }

    .nav-list{
        font-size: 23px;
    }

}

@media screen and (max-width: 1690px) {

    .unequip-img, .locked-img{
        margin-top: 100px;
    }

    .header{
        height: 140px;
        margin-bottom: 10px;
    }
    .header-stats__title{
        font-size: 16px;
    }


    .main-main-list{
        height: 355px;
    }

    .main-workplace-header .time{
        font-size: 14px;
    }

    .main-main .main-title{
        background-size: 40%;
    }


    .main-workplace{
        height: 515px;
    }

    .workplace .main-main{
        width: 1000px;
        height: 515px;
    }

    .main-main-wrapper{
        padding: 50px 105px 44px 105px;
    }

    .workplaces-item{
        width: 180px;
        height: 345px;
    }

    .main-workplace-sidebar{
        width: 220px;
        height: 575px;
    }

    .workplaces-img_name{
        font-size: 14px;
    }

    .main-workplace-sidebar__item{
        width: 145px;
        height: 200px;
    }

    .main-main .main-title{
        background-size: 32%;
    }

    .main-main .main-title h2{
        font-size: 27px;
    }

    .start-work_btn{
        width: 120px;
        height: 35px;
    }

    .start-work_btn {
        padding: 5px 15px;
        font-size: 18px;
    }

    .btn-equip button{
        font-size: 16px;
    }

    .btn-unequip button{
        font-size: 16px;
    }


    .btn-lock button{
        font-size: 16px;
    }

    .produces{
        font-size: 16px;
    }

}

@media screen and (max-width: 1599px) {
    .main-workplace{
        height: 515px;
    }

    .main-main-list{
        height: 355px;
    }

    .workplace .main-main{
        width: 950px;
        height: 510px;
    }

    .main-main .main-title{
        background-size: 30%;
    }

    .main-main .main-title h2{
        font-size: 21px;
    }

    .workplaces-item{
        width: 165px;
        height: 345px;
    }

    .btn-equip button{
        font-size: 14px;
        padding: 5px 30px;
    }

    .btn-unequip button{
        font-size: 14px;
        padding: 5px 30px;
    }


    .btn-lock button{
        font-size: 14px;
        padding: 5px 30px;
    }

    .main-workplace-sidebar{
        width: 180px;
        height: 500px;
    }

    .main-workplace-sidebar__item{
        width: 115px;
        height: 160px;
    }

    .nav-list{
        font-size: 16px;
    }

    .nav-list li{
        padding: 10px;
    }

    .nav-list li img{
        width: 33px;
        padding: 10px;
    }
}

/*@media screen and (max-width: 1540px) {*/
/*    .workplace .main-main{*/
/*        margin-right: 215px;*/
/*    }*/
/*}*/


/*@media screen and (max-width: 1680px) {*/
/*    .workplaces-item{*/
/*        width: 200px;*/
/*        height: 360px;*/
/*        background-color: rgba(0, 0, 0, 0.103);*/
/*        border-radius: 10px;*/
/*    }*/
/*}*/



