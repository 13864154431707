/*.market{*/
/*    background-image: url('../../images/ingame_background.png');*/
/*    background-position: center;*/
/*    position: fixed;*/
/*    width: 100%;*/
/*    height: 100vh;*/
/*    overflow: auto;*/
/*}*/


.main-main-contant.market{
    justify-content: center;
}

/*.main-workplace.market .main-main-contant{*/
/*    padding: 0 0 20px 0;*/
/*    overflow: auto;*/
/*}*/

.main-workplace.market .market-list{
    width: 100%;
    height: 385px;
    padding: 0 0 15px 0;
    overflow-y: auto;
    overflow-x: hidden;
}

.main-workplace.market{
    justify-content: center;
}

.main-workplace.market .main-main{
    margin: 0;
}

.main-workplace.market .container{
    height: 580px;
    padding: 85px 85px 0 111px;
}

.header-market{
    margin-bottom: 5px;
}

.header-market__wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-market__wrapper .filter select{
    margin: 10px;

    border: 2px solid #512B47;
    background: transparent;
    padding: 10px 30px;
    text-align: left;
    color: #512B47;
}

.header-market__wrapper button{
    color: #E8D9BF;
    background: #3b1d36;
    padding: 10px 30px;
    border: 0;
    cursor: pointer;
}

.no-inventory{
    justify-content: center;
    align-items: center;
    font-size: 25px;
    font-weight: bold;
    text-transform: uppercase;
    height: 410px;
}

.market-list{
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    width: 991px;
    height: 304px;
    /* UI Properties */
    background: #B99068 0 0 no-repeat padding-box;
    border: 2px solid #AE845B;
    border-radius: 10px;
    opacity: 1;
}

.market-list_item{
    height: 161px;
    margin: 10px 10px;
    cursor: pointer;
    position: relative;
}


.market-list_item:hover{
    background-image: url('../../images/select_workplace.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: 2;
}

.market-list_item.selected{
    background-image: url('../../images/select_workplace.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: 2;
}

.list-item-wrapper{
    width: 108px;
    height: 153px;
}

.market-list_item img{
    width: 100%;
    height: 100%;
    transform: scale(0.94);
    padding-top: 5px;
}


.market-btn{
    margin-top: 17px;
}

.market-btn button{
    background-color: transparent;
    background-image: url("../../images/green_btn.png");
    background-size: cover;
    background-repeat: no-repeat;
    padding: 10px 20px;
    color: #fff;
    border: 0;
    font-size: 22px;
    display: block;
    margin: 0 auto;
    cursor: pointer;
}

.market-lvl{
    color: #fff;
    font-weight: bold;
}

.market-lvl p {
    font-size: 20px;
    padding: 0;
    margin: 0;
}

@media screen and (max-width: 1815px){
    .main-workplace.market .market-list{
        height: 360px;
    }
}


@media screen and (max-width: 1690px) {
    .market .main-main{
        height: 515px;
    }

    .main-workplace.market .container{
        height: 430px;
    }

    .header-market__wrapper .filter select{
        padding: 5px 15px;
    }

    .header-market__wrapper button{
        padding: 5px 15px;
    }

    .main-workplace.market .container{
        padding: 45px 85px 0 111px;
    }

    /*.market-list_item.selected, .market-list_item:hover{*/
    /*    width: 105px;*/
    /*    height: 161px;*/
    /*}*/

    .market-list{
        width: 100%;
        height: 230px;
    }

    .main-workplace.market .market-list{
        height: 305px;
    }

    .list-item-wrapper{
        width: 105px;
        height: 150px;
    }

    .inventory .container{
        padding: 90px 55px;
    }

    .inventory-item{
        width: 200px;
        height: 355px;
    }

    .inventory-item__btn button{
        font-size: 18px;
        padding: 5px 35px;
    }
}