.upgrade {
    font-size: 12px;
    background: url('../images/popup1_bg.png');
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    color:#815F50;
    padding: 20px 30px;
  }
.upgrade > .header-modal {
    width: 100%;
    font-size: 28px;
    font-weight: 700;
    text-align: center;
    padding: 20px 0 25px 0;
    color: #FFFFFF;
  }
.upgrade > .content {
    width: 100%;
    padding: 10px 5px;
    text-align: center;
}

.upgrade > .content img{
    padding: 5px;
}

.upgrade > .content p{
      padding-bottom: 10px;
}

.upgrade > .actions {
    width: 100%;
   
    margin: auto;
    text-align: center;
}
.upgrade > .actions button{
      font-size: 22px;
    color: #FFFFFF;
    background-color: transparent;
    border: 0;
      font-weight: bold;
      background-image: url("../images/green_btn.png");
        background-size: cover;
    background-repeat: no-repeat;
        cursor: pointer;
      padding: 5px 40px;
}

  
.upgrade > .close{
    position: absolute;
    right: -28px;
    top: 3%;
    cursor: pointer;
}

 
.popup-overlay {
    background: rgba(0, 0, 0, 0.822);
}

.popup-content {
    max-width: 900px;
    height: 507px;
}

.upgrade .info-card{
    display: flex;
    margin-bottom: 33px;
}

.info-card__img{
    background: #6b4c3e3f;
    border-radius: 8px;
    margin: 0 30px 0 10px;
    width: 186px;
    height: 282px
}


.info-card__photo img{
    width: 100%;
    height: 100%;
}

.info-card__text h4{
    font-size: 24px;
}
.info-card__text p{
    padding: 0;
}



.info-card ul{
    flex-direction: column;
    text-align: left;
    margin-right: 60px;
}
.info-card__progress{
    margin-top: 17px;
}
.upgrade .info-card__progress p:first-child{
    text-align: center;
    margin: 0;
}

.upgrade .info-card ul li{
    font-size: 14px;
    margin-top: 17px;
    color: #512B47;
    list-style-type: none;
}

.upgrade .info-card ul span{
    margin-bottom: 17px;
    color: #512B47;
}

.info-card ul span{
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}

.info-card__progress ul{

}

.info-card__progress li{
    font-weight: bold;
    color: #FFFFFF!important;;
    list-style-type: none;
}

.li-wrapper{
    display: flex;
    align-items: center;

}

.li-wrapper p{
    color: #FFFFFF;
}

.info-card__progress p:first-child{
    font-size: 16px;
    font-weight: 400;
    color: #512B47!important;
    text-align: center;
}

.info-card__progress p {
    padding-bottom: 20px;
    color: #fff!important;
    font-weight: bold;
}

.info-card__progress img{
    width: 30px;
}