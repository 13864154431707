
.main-main-contant.swap{
    justify-content: center;
}

.main-workplace.swap .market-list{
    width: 100%;
    height: 385px;
    padding: 0 0 15px 0;
    overflow-y: auto;
    overflow-x: hidden;
}

.main-workplace.market .main-main{
    margin: 0;
}

.main-workplace.swap .swap-container{
    height: 485px;
    padding: 128px 85px 55px 85px;
}

.main-workplace.swap .swap-container .swap-block{
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.main-workplace.swap .swap-container .swap-block .swap-btn{
    display: block;
    color: #ffffff;
    font-size: 22px;
    background-image: url('../../images/green_btn.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-color: transparent;
    width: 158px;
    height: 48px;
    text-align: center;
    margin: 0 auto;
    cursor: pointer;
    border: 0;
}

.main-workplace.swap .swap-container .swap-block .swap-btn.disabled{
    background-image: url('../../images/unactive_green_btn.png');
    cursor: default;
    background-color: transparent;
}

.main-workplace.swap .swap-container .swap-block .swap-btn p{
    margin: 0;
    padding: 8px;
}

.main-workplace.swap .swap-container .swap-block .swiper-field{
    display: flex;
    justify-content: space-between;
    background-image: url('../../images/section_inventory.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 570px;
    height: 110px;
    margin-bottom: 18px;
}

.main-workplace.swap .swap-container .swap-block .swiper-field .options{
    display: flex;
    flex-direction: column;
}

.main-workplace.swap .swap-container .swap-block .swiper-field .result{
    width: 40%;
    padding: 10px 11px 10px 48px;
    margin: 25px 0 28px 0;
    font-size: 30px;
    color: #ffffff;
    font-weight: bold;
    background-color: transparent;
    border: 0;
}

.main-workplace.swap .swap-container .swap-block .swiper-field .resource-block{
    width: 157px;
    height: 44px;
    margin: 15px 13px 0 0;
    /* UI Properties */
    background: #251732 0 0 no-repeat padding-box;
    box-shadow: 0 3px 3px #00000099;
    border-radius: 20px;
    opacity: 1;
    color: #ffffff;
    font-size: 18px;
    text-align: center;
    border: 0;
}

.main-workplace.swap .swap-container .swap-block .swiper-field .resource-amount{
    margin: 0;
    font-size: 12px;
    padding-top: 8px;
    cursor: pointer;
    padding-bottom: 5px;
    text-align: center;
    color: #FFFFFF;
}

.main-workplace.swap .swap-container .swap-block .swiper-field .resource-block:focus{
    outline: none;
    border: 0;
}

.main-workplace.swap .swap-container .swap-block .swiper-field .resource-block p{
    margin: 10px;
    text-align: center;
    font-weight: bold;
}

.main-workplace.swap .swap-container .swap-block .swiper-field .token-block{
    width: 157px;
    height: 44px;
    margin: 15px 13px 0 0;
    /* UI Properties */
    background: #251732 0 0 no-repeat padding-box;
    box-shadow: 0 3px 3px #00000099;
    border-radius: 20px;
    opacity: 1;
    color: #ffffff;
    font-size: 18px;
}

.main-workplace.swap .swap-container .swap-block .swiper-field .token-block .balance{
    margin: 0;
    font-size: 12px;
    padding-top: 8px;
    cursor: pointer;
    padding-bottom: 5px;
}


.main-workplace.swap .swap-container .swap-block .swiper-field .token-block p{
    margin: 12px;
    text-align: center;
    font-weight: bold;
}

.main-workplace.swap .swap-container .swap-block .swiper-field input{
    width: 40%;
    padding: 10px 11px 10px 48px;
    margin: 27px 0 28px 0;
    font-size: 30px;
    color: #ffffff;
    font-weight: bold;
    background-color: transparent;
    border: 0;
}

.main-workplace.swap .swap-container .swap-block .swiper-field input:focus{
    outline: none;
}

.main-workplace.swap .swap-container .statistic{
    width: 443px;
    color: #ffffff;
}

.main-workplace.swap .swap-container .statistic h4{
    text-align: center;
    font-size: 20px;
    margin-bottom: 17px;
}

.main-workplace.swap .swap-container .statistic h5{
    text-align: start;
    font-size: 18px;
    margin: 0;
}

.main-workplace.swap .swap-container .statistic h4, .main-workplace.swap .swap-container .statistic h5{
    color: #000000;
}

.main-workplace.swap .swap-container .statistic .statistic-field{
    font-size: 25px;
    margin-bottom: 18px;
    border-bottom: 1px solid #512B47;
    padding-left: 40px;
}

.main-workplace.swap .swap-container .statistic .statistic-field p{
    margin: 11px 0 11px 0;
}


@media screen and (max-width: 1815px){

    .main-workplace.swap .swap-container{
        padding: 110px 85px 35px 85px;
    }

    .main-workplace.swap .swap-container .swap-block .swiper-field{
        width: 460px;
        height: 90px;
        background-size: contain;
    }

    .main-workplace.swap .swap-container .swap-block .swiper-field .resource-block{
        font-size: 16px;
    }

    .main-workplace.swap .swap-container .swap-block .swiper-field .token-block{
        font-size: 16px;
    }

    .main-workplace.swap .swap-container .swap-block .swiper-field .token-block .balance{
        font-size: 10px;
    }

    .main-workplace.swap .swap-container .swap-block .swiper-field .resource-amount{
        font-size: 10px;
    }

    .main-workplace.swap .swap-container .swap-block .swiper-field .result{
        margin: 15px 0 17px 0;
        font-size: 27px;
    }

    .main-workplace.swap .swap-container .statistic{
        width: 400px;
    }

    .main-workplace.swap .swap-container .swap-block .swiper-field input{
        font-size: 28px;
    }
}

@media screen and (max-width: 1699px){

    .main-workplace.swap .swap-container {
        padding: 110px 85px 35px 85px;
    }

    .swap-container{
        height: 75%;
    }

    .main-workplace.swap .swap-container .swap-block .swiper-field{
        width: 400px;
        height: 78px;
        background-size: contain;
    }

    .main-workplace.swap .swap-container .swap-block .swiper-field .resource-block{
        font-size: 14px;
    }

    .main-workplace.swap .swap-container .swap-block .swiper-field .token-block{
        font-size: 14px;
    }

    .main-workplace.swap .swap-container .swap-block .swiper-field .token-block .balance{
        font-size: 9px;
    }

    .main-workplace.swap .swap-container .swap-block .swiper-field .resource-amount{
        font-size: 9px;
    }

    .main-workplace.swap .swap-container .statistic{
        width: 400px;
    }

    .main-workplace.swap .swap-container .swap-block .swiper-field input{
        font-size: 28px;
    }

    .main-workplace.swap .swap-container .swap-block .swiper-field .result{
        margin: 10px 0 10px 0;
    }

    .main-workplace.swap .swap-container .swap-block .swiper-field .token-block{
        margin: 0;
        border: 0;
    }

    .main-workplace.swap .swap-container .swap-block .swiper-field .token-block, .main-workplace.swap .swap-container .swap-block .swiper-field .resource-block{
        width: 125px;
        height: 30px;
        margin: 8px 13px 0 0;
    }

    .main-workplace.swap .swap-container .swap-block .swiper-field .token-block p, .main-workplace.swap .swap-container .swap-block .swiper-field .resource-block p {
        margin: 6px;
    }

    .main-workplace.swap .swap-container .statistic{
        width: 350px;
    }
}

@media screen and (max-width: 1599px){
    .main-workplace.swap .swap-container {
        padding: 65px 85px 35px 85px;
        height: 400px;
    }
}


